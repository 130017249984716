import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tile from "../components/tile"
import Text from '../styles/text'
import PageStyled from '../styles/page'
import TileStyled from '../styles/tile'
import { mountains, sun, edsLogo, polyconsLogo, edpressoLogo, projectsLogo, resdesignLogo, pmwLogo, logosLogo } from '../images/logos'

const IndexPage = (props) => {
  return (
    <Layout path={props.path}>
      <SEO title="Home" />
      <PageStyled bg={['#F4F9FF', '#FFF8FC']}>
        <PageStyled.Content>
          <Text>Hi, I'm Abdullah</Text>
          <Text.Hero><strong>Designer</strong> of experiences</Text.Hero>
          <Text.Hero><strong>Deviser</strong> of brand strategies</Text.Hero>
          <PageStyled.Sun>{sun}</PageStyled.Sun>
          {mountains}
        </PageStyled.Content> 
      </PageStyled>
      <PageStyled.Content2>
        <TileStyled.Tiles>
          <Tile
            path='/pmw'
            heading="PosterMyWall - Design System, Branding, UX"
            description="Empowering small businesses to market their products and services through easy to create designs."
            bg={['#e1f7ff', '#f1fbff']}
            image={pmwLogo}
          />   
          <Tile
            path='/eds'
            heading="Educative - Design System"
            description="Unified design language and process for developers, designers and marketing folks at educative."
            bg={['#f3efff', '#fbfaff']}
            image={edsLogo}
          />      
        </TileStyled.Tiles>
        <TileStyled.Tiles>
          <Tile
            path='/educative'
            heading="Educative - Redesign 2018"
            description="Made for developers by developers to enhance their skillset on pre-configured browser environments."
            bg={['#f3efff', '#fbfaff']}
            image={resdesignLogo}
          />    
          <Tile
            path='/edpresso'
            heading="Edpresso - Product Design"
            description="Collection of byte sized articles to provide answers and knowledge of basic dev terms, queries and concerns."
            bg={['#f7eee6', '#fffaf6']}
            image={edpressoLogo}
          />   
        </TileStyled.Tiles>
        <TileStyled.Tiles>
        <Tile
            path='/polycons'
            heading="Polycons - Icon and Product Design"
            description="Gallery of icons with 5 different styles and controls (color, thickness, etc.) to go with any project."
            bg={['#E7FAF7', '#f8fffe']}
            image={polyconsLogo}
          />
          <Tile
            path='/projects'
            heading="Web Design"
            description="Client projects comprising of web design. Primarily everything visual."
            bg={['#e1f7ff', '#f1fbff']}
            image={projectsLogo}
          />
        </TileStyled.Tiles>
        <TileStyled.Tiles>
        
          <Tile
            path='/projectsLogo'
            heading="Logo Design"
            description="Client projects ranging from logo design to complete brand identity."
            bg={['#e1f7ff', '#f1fbff']}
            image={logosLogo}
          />  
        </TileStyled.Tiles>
      </PageStyled.Content2>
    </Layout>
  )
}

export default IndexPage
